<template>
    <b-container>
        <h1>Impordi kandeid pangast</h1>

		<b-form v-if="$store.state.teamData" inline>
			<b-form-group
				label-for="file"
				description="Fail peab olema .csv tüüpi"
			>
				<b-form-file
					v-model="importable.file"
					id="file"
					accept=".csv"
				></b-form-file>
			</b-form-group>
			<b-form-group
					class="ml-2"
					label-for="type"
					description="Pank"
			>
				<b-form-select
					id="type"
					v-model="importable.type"
					:options="importable.types"
				></b-form-select>
			</b-form-group>
			<b-form-group
					class="ml-2"
					label-for="member"
					description="Pereliige"
			>
				<b-form-select
					id="member"
					v-model="importable.member"
					:options="$store.state.teamData.current_team.members"
				></b-form-select>
			</b-form-group>
			<b-button
					variant="success"
					class="ml-2"
					style="margin-bottom: 1.38rem"
					@click="importFile"
					:disabled="!(importable.file && importable.member && importable.type)"
			>
				Impordi
			</b-button>
		</b-form>

		<div v-if="importable.spinner" class="text-center text-primary my-2">
			<b-spinner variant="primary" class="align-middle"></b-spinner>
		</div>

		<b-table-simple v-if="importRows && importRows.length > 0" class="mt-3" small hover responsive>
			<b-thead head-variant="light">
				<b-tr>
					<b-th>#</b-th>
					<b-th>Importis</b-th>
					<b-th>Aeg</b-th>
					<b-th>Ridu</b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr v-for="(row, iIdx) in importRows" :key="iIdx">
					<b-td>{{ iIdx + 1 }}</b-td>
					<b-td>{{ row.user_name }}</b-td>
					<b-td>{{ row.time }}</b-td>
					<b-td>{{ row.count }}</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>
		<b-alert v-else variant="info" class="mt-3" show>
			Andmeid pole veel ühtegi korda imporditud.
		</b-alert>

    </b-container>
</template>

<script>

	import _ from 'lodash';
	import moment from 'moment';

	export default {
		data () {
			return {
				importable: {
					spinner: false,
					file: null,
					member: null,
					type: null,
					types: [
						{value: 'swedbank', text: 'Swedbank'},
						{value: 'seb', text: 'SEB', disabled: true},
						{value: 'lhv', text: 'LHV', disabled: true},
					],
				},
				importRows: [],
			}
		},
		created() {
			this.loadImports();
		},
		methods: {
			loadImports() {
				this.isBusy = true;
				axios.get('/imports').then(data => {
					this.importRows = data.data;
				});
			},
			importFile() {
				this.importable.spinner = true;
				const data = new FormData();
				data.append('type', this.importable.type);
				data.append('member', this.importable.member);
				data.append('file', this.importable.file);
				axios.post("/import", data).then(({data}) => {
					this.importable.spinner = false;
					this.$router.push({ path: '/transactions' });
				});
			},
		}
	}
</script>
