<template>
    <b-container>

		<h1 class="mt-0 mb-0">Sissekanded</h1>

		<b-row>
			<b-col>
				<b-pagination
					v-model="currentPage"
					:total-rows="stats.totalRows"
					:per-page="perPage"
					align="fill"
					size="sm"
					class="my-0 mb-3"
					@input="loadTransactions()"
				></b-pagination>
			</b-col>
		</b-row>

		<b-row>
			<b-col>
				<template v-if="stats.totalRows > 0">
					Kandeid: <b>{{ stats.totalRows }} tk</b>,
					Sissetulekuid: <b>{{ stats.incomingSum }} €</b>,
					Väljaminekuid: <b>{{ stats.outgoingSum }} €</b>,
					Rahavoog: <b>{{ stats.totalSum }} €</b>
				</template>
			</b-col>
		</b-row>

		<b-row class="mt-2 mb-2">
			<b-col>
				<b-row>
					<b-col class="text-left">
						<b-link @click="moreFilters.visible = !moreFilters.visible">
							{{ moreFilters.visible ? 'Vähem filtreid' : 'Rohkem filtreid' }}
						</b-link>
					</b-col>
					<b-col>
						<b-button v-if="isFiltered"
								  variant="danger"
								  @click="resetFilters"
								  size="sm"
								  class="float-right mb-2"
						>
							Kustuta filtrid
						</b-button>
						<b-button v-if="stats.untagged > 0"
								  variant="warning"
								  @click="filterUntagged"
								  size="sm"
								  class="float-right mb-2 mr-1"
						>
							Kategoriseerimata ({{ stats.untagged }})
						</b-button>
					</b-col>
				</b-row>
				<b-row v-if="moreFilters.visible">
					<b-col>
						<b-row>
							<b-col class="text-right">
								Kategooria:
							</b-col>
							<b-col>
								<v-select v-if="$store.state.tags.list"
										  label="text"
										  :options="$store.state.tags.list"
										  v-model="moreFilters.tag"
										  @input="filterUpdated"
										  style="min-width: 300px;"
								>
									<template #option="{ text, value, depth, is_leaf, prefix }">
										<em v-for="i in depth" style="color:#d0d0d0;">- </em>
										<em v-if="is_leaf">{{ prefix }} {{ text }}</em>
										<b v-else>{{ prefix }} {{ text }}</b>
									</template>
								</v-select>
							</b-col>
							<b-col lg>

							</b-col>
						</b-row>
						<b-row class="mt-1">
							<b-col class="text-right">
								Alates:
							</b-col>
							<b-col>
								<b-form-datepicker
									id="start"
									v-model="moreFilters.period.start"
									:start-weekday="1"
									:date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
									locale="et"
									label-current-month="Praegune kuu"
									label-next-month="Järgmine kuu"
									label-prev-month="Eelmine kuu"
									label-next-year="Järgmine aasta"
									label-prev-year="Eelmine aasta"
									@input="moreFilters.period.end !== '' ? filterUpdated() : false"
									size="sm"
									placeholder="Vali algus"
									right
								></b-form-datepicker>
							</b-col>
							<b-col lg>

							</b-col>
						</b-row>
						<b-row class="mt-1">
							<b-col class="text-right">
								Kuni:
							</b-col>
							<b-col>
								<b-form-datepicker
									id="end"
									v-model="moreFilters.period.end"
									:start-weekday="1"
									:date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
									locale="et"
									label-current-month="Praegune kuu"
									label-next-month="Järgmine kuu"
									label-prev-month="Eelmine kuu"
									label-next-year="Järgmine aasta"
									label-prev-year="Eelmine aasta"
									@input="moreFilters.period.start !== '' ? filterUpdated() : false"
									size="sm"
									placeholder="Vali lõpp"
									right
								></b-form-datepicker>
							</b-col>
							<b-col lg>

							</b-col>
						</b-row>
						<b-row class="mt-1">
							<b-col class="text-right">
								Alates:
							</b-col>
							<b-col>
								<b-input-group prepend="€" size="sm">
									<b-form-input
										v-model="moreFilters.amount.min"
										type="number"
										style="text-align: right;"
										@change="moreFilters.amount.max !== '' ? filterUpdated() : false"
									></b-form-input>
								</b-input-group>
							</b-col>
							<b-col lg>

							</b-col>
						</b-row>
						<b-row class="mt-1">
							<b-col class="text-right">
								Kuni:
							</b-col>
							<b-col>
								<b-input-group prepend="€" size="sm">
									<b-form-input
										v-model="moreFilters.amount.max"
										type="number"
										style="text-align: right;"
										@change="moreFilters.amount.min !== '' ? filterUpdated() : false"
									></b-form-input>
								</b-input-group>
							</b-col>
							<b-col lg>

							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-col>
		</b-row>


		<b-table
				ref="transactionsTable"
				:items="transactions"
				:fields="fields"
				:busy="isBusy"
				:sort-by="sortBy"
				:sort-desc="sortDesc"
				:no-local-sorting="true"
				responsive="sm"
				small
				@sort-changed="sortUpdated"
		>
			<template #table-busy>
				<div class="text-center text-primary my-2">
					<b-spinner variant="primary" class="align-middle"></b-spinner>
				</div>
			</template>

			<template #thead-top="data">
				<b-tr>
					<b-th v-for="field in data.fields" :key="field.key">
						<input	v-if="field.key != 'admin'"
								type="text"
								class="form-control form-control-sm"
								style="text-align: center;"
								v-model="filters[field.key]"
								@change="filterUpdated"
						>
						<b-button
								v-if="field.key == 'admin'"
								@click="createRow()"
								variant="success"
								size="sm"
								:disabled="originalRow !== false"
						>
							<b-icon icon="plus"></b-icon>
						</b-button>
					</b-th>
				</b-tr>
			</template>

			<template #cell(date)="data">
				<div v-if="!data.detailsShowing || (!data.item.id && data.item.transaction_id)" class="text-nowrap">
					{{ data.item.date }}
				</div>
				<div v-else>
					<b-form-datepicker
							v-model="data.item.date"
							locale="et"
							size="sm"
							start-weekday="1"
							:date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit'}"
					></b-form-datepicker>
				</div>
			</template>

			<template #cell(amount)="data">
				<div v-if="!data.detailsShowing" class="text-right">
					<span v-if="data.item.amount > 0" style="color: #51b44f;">
						+ {{ (data.item.amount * 1).toFixed(2) }}
					</span>
						<span v-else style="color: #e64b30;">
						- {{ (-data.item.amount * 1).toFixed(2) }}
					</span>
				</div>
				<div v-else>
					<b-form inline>
						<b-input-group>
							<b-button v-if="data.item.is_negative"
									  variant="danger"
									  size="sm"
									  @click="toggleAmountDirection(data.item)"
							>
								<b-icon icon="dash"></b-icon>
							</b-button>
							<b-button v-else
									  variant="success"
									  size="sm"
									  @click="toggleAmountDirection(data.item)"
							>
								<b-icon icon="plus"></b-icon>
							</b-button>
							<b-form-input
								v-model="data.item.amount"
								type="number"
								size="sm"
								style="text-align: right;"
								@update="updateSums(data.item)"
							></b-form-input>
						</b-input-group>
					</b-form>
				</div>
			</template>

			<template #cell(comment)="data">
				<div v-if="!data.detailsShowing">
					{{ data.item.comment }}
				</div>
				<div v-else>
					<b-form-input
							v-model="data.item.comment"
							size="sm"
					></b-form-input>
				</div>
			</template>

			<template #cell(teammember)="data">
				<div v-if="!data.detailsShowing">
					{{ data.item.teammember }}
				</div>
				<div v-else>
					<b-form-select
							v-model="data.item.teammember_id"
							:options="$store.state.teamData.current_team.members"
							@change="teammemberPicked(data.item)"
							size="sm"
					></b-form-select>
				</div>
			</template>

			<template #cell(tag_text)="data">
				<div v-if="editableTag.transactionRow && editableTag.transactionRow.id == data.item.id">
					<v-select v-if="$store.state.tags.list"
							  label="text"
							  :options="$store.state.tags.list"
							  v-model="data.item.tag"
							  style="min-width: 300px; line-height: 1.6em;"
							  class="m-1"
							  @search:blur="saveTag(data.item, data.item.tag)"
					>
						<template #option="{ text, value, depth, is_leaf, prefix }">
							<em v-for="i in depth" style="color:#d0d0d0;">- </em>
							<em v-if="is_leaf">{{ prefix }} {{ text }}</em>
							<b v-else>{{ prefix }} {{ text }}</b>
						</template>
					</v-select>
				</div>
				<div class="text-center"
					 v-else
					 @click="editTag(data.item)"
					 v-bind:style="data.item.is_autotagged ? 'color: #51b44f' : ''"
				>
					<b-link>{{ data.item.tag_text ? data.item.tag_text : '-' }}</b-link>
				</div>
			</template>

			<template #cell(admin)="row">
				<b-button
						@click="editRow(row)"
						variant="primary"
						size="sm"
						:disabled="originalRow !== false"
				>
					<b-icon icon="pencil"></b-icon>
				</b-button>
			</template>

			<template #row-details="row">
				<b-card>
					<b-button size="sm" @click="cancelEdit(row)" class="ml-1">
						Tühista
					</b-button>
					<b-button v-if="row.item.id" size="sm" @click="deleteRow(row)" variant="danger" class="ml-1">
						Kustuta
					</b-button>
					<b-button v-if="row.item.id" size="sm" @click="splitRow(row)" variant="primary" class="ml-1">
						Poolita
					</b-button>
					<b-button :disabled="!canBeSaved(row)" size="sm" @click="saveRow(row)" variant="success" class="ml-1">
						Salvesta
					</b-button>
				</b-card>
			</template>

		</b-table>

		<b-pagination
				v-model="currentPage"
				:total-rows="stats.totalRows"
				:per-page="perPage"
				align="fill"
				size="sm"
				class="my-0"
		></b-pagination>

    </b-container>
</template>

<script>

	import _ from 'lodash';
	import moment from 'moment';

	export default {
		data () {
			return {
				sortBy: 'date',
				sortDesc: true,
				isBusy: false,
				currentPage: 1,
				perPage: 100,
				stats: {
					totalRows: 0,
					incomingSum: 0,
					outgoingSum: 0,
					totalSum: 0,
					untagged: 0,
				},
				filters: {
					date: '',
					amount: '',
					comment: '',
					teammember: '',
					tag_text: '',
				},
				moreFilters: {
					visible: false,
					tag: false,
					period: {
						start: '',
						end: '',
					},
					amount: {
						min: '',
						max: '',
					}
				},
				fields: [
					{ key: 'date', label: 'Kuupäev', sortable: true },
					{ key: 'amount', label: 'Summa', sortable: true },
					{ key: 'comment', label: 'Kommentaar', sortable: true },
					{ key: 'teammember', label: 'Pereliige', sortable: true },
					{ key: 'tag_text', label: 'Kategooria', sortable: true },
					{ key: 'admin', label: '', sortable: false },
				],
				transactions: [],
				originalRow: false,
				editableTag: {
					transactionRow: null,
				},
				rowTemplate: {
					id: false,
					transaction_id: false,
					is_negative: true,
					amount: false,
					comment: false,
					date: false,
					teammember_id: false,
					temmember: false,
					tag_id: false,
					tag_text: false,
					_showDetails: false,
					_cellVariants: {
						tag_text: 'warning',
					}
				}
			}
		},
		created() {
			this.$store.dispatch('loadTags');
			this.loadTransactions();
		},
		computed: {
			isFiltered: function() {
				if (this.moreFilters.tag || this.moreFilters.period.start !== '' || this.moreFilters.period.start !== ''
						|| this.moreFilters.amount.min !== '' || this.moreFilters.amount.max !== '') {
					return true;
				}
				for (var name in this.filters) {
					if (this.filters.hasOwnProperty(name)) {
						if (this.filters[name] != '') return true;
					}
				}
				return false;
			},
		},
		methods: {
			resetFilters: function() {
				this.moreFilters.tag = false;
				this.moreFilters.period.start = '';
				this.moreFilters.period.end = '';
				this.moreFilters.amount.min = '';
				this.moreFilters.amount.max = '';
				for (var name in this.filters) {
					if (this.filters.hasOwnProperty(name)) {
						this.filters[name] = '';
					}
				}
				this.filterUpdated();
			},
			filterUpdated () {
				this.loadTransactions();
			},
			filterUntagged() {
				this.filters.tag_text = '-';
				this.filterUpdated();
			},
			sortUpdated (event) {
				this.sortBy = event.sortBy;
				this.sortDesc = event.sortDesc;
				this.loadTransactions();
			},
			loadTransactions() {
				this.isBusy = true;
				let tagFilter = false;
				if (this.moreFilters.tag) {
					tagFilter = this.$store.state.tags.tags[this.moreFilters.tag.value];
					tagFilter = JSON.stringify({left: tagFilter._lft, right: tagFilter._rgt});
				}
				let periodFilter = false;
				if (this.moreFilters.period.start !== '' && this.moreFilters.period.end !== '') {
					periodFilter = JSON.stringify(this.moreFilters.period);
				}
				let amountFilter = false;
				if (this.moreFilters.amount.min !== '' && this.moreFilters.amount.max !== '') {
					amountFilter = JSON.stringify(this.moreFilters.amount);
				}
				const data = new FormData();
				data.append('filters', JSON.stringify(this.filters));
				data.append('tagFilter', tagFilter);
				data.append('periodFilter', periodFilter);
				data.append('amountFilter', amountFilter);
				data.append('sortBy', this.sortBy);
				data.append('sortDesc', this.sortDesc);
				data.append('currentPage', this.currentPage);
				data.append('perPage', this.perPage);
				axios.post('/transactions', data).then(data => {
					this.transactions = data.data.rows;
					this.stats = data.data.stats;
					if (data.status == 200) {
						this.isBusy = false;
						this.$refs.transactionsTable.refresh();
					}
				});
			},
			editRow(row) {
				row.toggleDetails();
				this.originalRow = JSON.parse(JSON.stringify(row.item));
			},
			cancelEdit(row) {
				row.item.amount = this.originalRow.amount;
				row.item.is_negative = this.originalRow.is_negative;
				row.item.comment = this.originalRow.comment;
				row.item.date = this.originalRow.date;
				row.item.teammember = this.originalRow.teammember;
				row.toggleDetails();
				// if canceling not yet saved row then remove it from transactions also
				if (!row.item.id) {
					this.transactions.splice(row.index, 1);
					this.$refs.transactionsTable.refresh();
				}
				if (row.item.id) {
					this.originalRow = false;
				}
			},
			canBeSaved(row) {
				if (!row.item.amount || row.item.amount == '') {
					return false;
				} else if (!row.item.teammember_id > 0) {
					return false;
				} else if (!row.item.tag_id > 0) {
					return false;
				} else if (!row.item.date || row.item.date == '') {
					return false;
				}
				return true;
			},
			saveRow(row) {
				if (row.item.id) {
					this.originalRow = false;
				}
				const data = new FormData();
				data.append('id', row.item.id);
				data.append('transaction_id', row.item.transaction_id);
				data.append('amount', row.item.amount);
				data.append('comment', row.item.comment);
				data.append('date', row.item.date);
				data.append('teammember_id', row.item.teammember_id);
				data.append('tag_id', row.item.tag_id);
				axios.post("/transaction", data).then(({data}) => {
					row.item.id = data.id;
					row.item.transaction_id = data.transaction_id;
				});
				row.toggleDetails();
			},
			editTag(transactionRow) {
				transactionRow.tag = {value: transactionRow.tag_id, text: transactionRow.tag_text};
				this.editableTag.transactionRow = transactionRow;
			},
			saveTag(transactionRow, tag) {
				transactionRow.tag_id = tag.value;
				transactionRow.tag_text = tag.text;
				transactionRow._cellVariants.tag_text = 'warning';
				if (transactionRow.id) {
					const data = new FormData();
					data.append('id', transactionRow.id);
					data.append('tag_id', tag.value);
					axios.post("/transaction_tag", data).then(({data}) => {
						this.editableTag.transactionRow = null;
					});
				} else {
					this.editableTag.transactionRow = null;
				}
			},
			teammemberPicked(row) {
				row.teammember = _.find(
					this.$store.state.teamData.current_team.members,
					{'value' : row.teammember_id}
				).text;
			},
			splitRow(row) {
				let splittedRow = JSON.parse(JSON.stringify(this.rowTemplate));
				splittedRow.transaction_id = row.item.transaction_id;
				splittedRow.date = row.item.date;
				splittedRow.amount = '';
				splittedRow.is_negative = row.item.is_negative;
				splittedRow.comment = row.item.comment;
				splittedRow.teammember = row.item.teammember;
				splittedRow.teammember_id = row.item.teammember_id;
				splittedRow.tag_text = '-';
				splittedRow._showDetails = true;
				this.transactions.splice(row.index + 1, 0, splittedRow);
				this.$refs.transactionsTable.refresh();
			},
			createRow() {
				let row = JSON.parse(JSON.stringify(this.rowTemplate));
				row.date = moment().format('YYYY-MM-DD');
				row.amount = '';
				row.comment = '';
				row.teammember = '';
				row.teammember_id = null;
				row.tag_text = '-';
				row._showDetails = true;
				this.transactions.unshift(row);
				this.$refs.transactionsTable.refresh();
			},
			deleteRow(row) {
				axios.delete("/transaction/"+row.item.id).then(({data}) => {
					this.transactions.splice(row.index, 1);
					this.$refs.transactionsTable.refresh();
				});
				this.originalRow = false;
			},
			updateSums(row) {
				if (row.is_negative) {
					row.amount = -Math.abs(row.amount);
				}
				// if updating splitted row
				if (!row.id) {
					// find splitted row original part
					let parentIdx = _.findIndex(this.transactions, {'id': this.originalRow.id});
					if (parentIdx > -1) {
						// find all splitted rows and sum up
						let splitSum = _.sumBy(this.transactions, function (tr) {
							return !tr.id ? tr.amount * 1 : 0;
						});
						this.transactions[parentIdx].amount = (this.originalRow.amount - splitSum).toFixed(2);
					}
				}
			},
			toggleAmountDirection(row) {
				if (row.amount) {
					row.amount = -row.amount;
				}
				row.is_negative = row.amount < 0;
				this.updateSums(row);
			}
		}
	}
</script>
